import {
  CBOEventReadModelDTONotSyncedProduct,
  CBOEventReadModelDTOSyncedProduct,
  OverrideableSyncedProductFields,
  V1,
} from '@bellepoque/api-contracts';
import { v4 as uuidv4 } from 'uuid';

import { CBOEventReadModelProductOptions, toCBOProductOptions } from './CBOEventReadModelProductOptions';

type CBOEventReadModelProductBase = {
  hasOptionsChecked: boolean;
  inventoryPolicy?: 'continue' | 'deny' | null;
  inventoryQuantity?: number | null;
  options: CBOEventReadModelProductOptions | null;
};

type CBOEventReadModelNotSyncedProduct = CBOEventReadModelProductBase &
  Omit<CBOEventReadModelDTONotSyncedProduct, 'createdAt' | 'options'>;

type CBOEventReadModelSyncedProduct = CBOEventReadModelProductBase &
  Omit<CBOEventReadModelDTOSyncedProduct, 'createdAt' | 'options'>;

export type CBOEventReadModelProductState = CBOEventReadModelNotSyncedProduct | CBOEventReadModelSyncedProduct;
export type CreateCBOEventReadModelProductParams = Partial<CBOEventReadModelProductState>;

export class CBOEventReadModelProduct {
  private constructor(private state: CBOEventReadModelProductState) {}

  get crossedOutPrice(): CBOEventReadModelProductState['crossedOutPrice'] {
    return this.state.crossedOutPrice;
  }

  get customerInternalId(): CBOEventReadModelProductState['customerInternalId'] {
    return this.state.customerInternalId;
  }

  get description(): CBOEventReadModelProductState['description'] {
    if (this.state.isSynced && this.state.overriddenFields.description) return this.state.overriddenFields.description;
    return this.state.description;
  }

  get hasOptionsChecked(): CBOEventReadModelProductState['hasOptionsChecked'] {
    return this.state.hasOptionsChecked;
  }

  get id(): CBOEventReadModelProductState['id'] {
    return this.state.id;
  }

  get inventoryPolicy(): CBOEventReadModelProductState['inventoryPolicy'] {
    return this.state.inventoryPolicy;
  }

  get inventoryQuantity(): CBOEventReadModelProductState['inventoryQuantity'] {
    return this.state.inventoryQuantity;
  }

  get imageUrls(): CBOEventReadModelProductState['imageUrls'] {
    if (this.state.isSynced && this.state.overriddenFields.imageUrls) return this.state.overriddenFields.imageUrls;
    return this.state.imageUrls;
  }

  get isSynced(): CBOEventReadModelProductState['isSynced'] {
    return this.state.isSynced;
  }

  get options(): CBOEventReadModelProductState['options'] {
    return this.state.options;
  }

  get price(): CBOEventReadModelProductState['price'] {
    return this.state.price;
  }

  get title(): CBOEventReadModelProductState['title'] {
    if (this.state.isSynced && this.state.overriddenFields.title) return this.state.overriddenFields.title;
    return this.state.title;
  }

  get url(): CBOEventReadModelProductState['url'] {
    return this.state.url;
  }

  static create(createParams: CreateCBOEventReadModelProductParams): CBOEventReadModelProduct {
    const initialState: CBOEventReadModelProductState = {
      crossedOutPrice: null,
      customerInternalId: '',
      description: '',
      hasOptionsChecked: false,
      id: uuidv4(),
      imageUrls: [],
      inventoryPolicy: undefined,
      inventoryQuantity: undefined,
      isSynced: false,
      mediaIds: [],
      options: null,
      price: null,
      title: '',
      url: null,
    };

    if (createParams.isSynced) {
      return new CBOEventReadModelProduct({
        ...initialState,
        ...createParams,
        isSynced: true,
        overriddenFields: createParams.overriddenFields ?? {
          description: null,
          imageUrls: null,
          title: null,
        },
      });
    }

    return new CBOEventReadModelProduct({
      ...initialState,
      ...createParams,
      isSynced: false,
    });
  }

  static fromState(state: CBOEventReadModelProductState): CBOEventReadModelProduct {
    return new CBOEventReadModelProduct(state);
  }

  toState(): CBOEventReadModelProductState {
    return this.state;
  }

  getOverriddenFieldsValues(): OverrideableSyncedProductFields {
    return {
      description: this.description,
      imageUrls: this.imageUrls,
      title: this.title,
    };
  }
}

export function getEmptyCBOProduct(): CBOEventReadModelProduct {
  return CBOEventReadModelProduct.create({});
}

export function toCBOProduct(productDTO: V1.readModels.CatalogProductReadModelDTO): CBOEventReadModelProduct {
  const options: CBOEventReadModelProductOptions | null = productDTO.options
    ? toCBOProductOptions(productDTO.options)
    : null;

  return CBOEventReadModelProduct.create({
    ...productDTO,
    hasOptionsChecked: !!options,
    options,
  });
}
