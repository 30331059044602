import { V1 } from '@bellepoque/api-contracts';
import { CopyAll, RequestQuote } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, TextField, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../../../../core/store';
import { generateActivationLink } from '../../../../core/usecases/administration/generate-activation-link';
import AdministrationHeader from '../../atoms/administration/AdministrationHeader';
import SettingsSection from '../../molecules/SettingsSection';
import GenerateActivationLinkForm from '../../molecules/administration/GenerateActivationLinkForm';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

export default function GenerateActivationLink() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['administration', 'common']);

  const { status } = useSelector((state: State) => state.administration.generateActivationLink);
  const response = useSelector((state: State) => state.administration.generateActivationLinkResult);

  const handleSubmit = async (request: V1.api.GenerateActivationLinkRequest) => {
    dispatch(generateActivationLink(request));
  };

  console.log({ status });

  return (
    <Container>
      <AdministrationHeader title={t('GenerateActivationLink')} />
      <ContentContainer mt={1}>
        <SettingsSection
          icon={<RequestQuote sx={{ color: (theme) => theme.palette.common.white }} />}
          roundBottom
          roundTop
          title={t('GenerateActivationLink')}
        >
          <SectionContainer>
            <FormContainer>
              <GenerateActivationLinkForm loading={status === 'pending'} onSubmit={handleSubmit} />
            </FormContainer>
            <Box>{status === 'pending' && <CircularProgress />}</Box>
            {status === 'success' && response && (
              <TextField
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        await navigator.clipboard.writeText(response.approvalUrl);
                      }}
                    >
                      <CopyAll />
                    </IconButton>
                  ),
                }}
                disabled
                fullWidth
                value={response.approvalUrl}
              />
            )}
            {status === 'error' && <Box color="red">An error occurred while creating the activation link</Box>}
          </SectionContainer>
        </SettingsSection>
      </ContentContainer>
    </Container>
  );
}
