export default {
  Activate: 'Activate',
  ActivationLink: 'Activation Link',
  Add: 'Add',
  AddAndOpen: 'Add and Open',
  AddValue: 'Add "{{value}}"',
  Admin: 'Admin',
  AdminTools: 'Tools',
  All_f: 'All',
  All_m: 'All',
  Analytics: 'Analytics',
  April: 'April',
  AreYouSure: 'Are you sure?',
  August: 'August',
  Cancel: 'Cancel',
  CancelModifications: 'Cancel',
  Clear: 'Clear',
  Close: 'Close',
  Cms: {
    CUSTOM: 'Custom',
    Cms: 'CMS',
    'PRESTASHOP1.6': 'PrestaShop1.6',
    'PRESTASHOP1.7': 'PrestaShop1.7',
    PRESTASHOP8: 'PrestaShop8',
    SFCC: 'SFCC',
    SHOPIFY: 'Shopify',
    UNKNOWN: 'Unknown',
    WOOCOMMERCE: 'WooCommerce',
  },
  Completed: 'Completed',
  Confirm: 'Confirm',
  ConfirmDelete: 'Confirm deletion',
  ConfirmDeleteText: 'Do you want to delete this element?',
  ConfirmDeleteText_other: 'Do you want to delete these {{count}} elements?',
  Copied: 'Copied',
  Copy: 'Copy',
  CopyAsHtml: 'Copy as HTML',
  CopyAsText: 'Copy as text',
  CopyLink: 'Copy link',
  CountryCode: 'Country code',
  Create: 'Create',
  CreateTenant: 'New Tenant',
  CrossedPrice: 'Crossed price',
  Currency: 'Currency',
  Customisation: 'Customisation',
  December: 'December',
  Delete: 'Delete',
  Details: 'Details',
  Disconnect: 'Disconnect',
  Display: 'Display',
  Done: 'Done',
  DragNDropImage: 'Drag and drop your image here',
  ErrorPleaseRetry: 'An error occurred, please retry.',
  February: 'February',
  Find: 'Find',
  Import: 'Import',
  Invitations: 'Invitations',
  January: 'January',
  July: 'July',
  June: 'June',
  Kb: 'Kb',
  LearnMore: 'Learn more',
  LetsDoIt: 'Let’s do it!',
  LinkCopied: 'Link copied!',
  March: 'March',
  Max: 'Max',
  MaxCharsWithCount: '{{count}} character max',
  MaxCharsWithCount_other: '{{count}} characters max',
  May: 'May',
  MinCharsWithCount: '{{count}} character min',
  MinCharsWithCount_other: '{{count}} characters min',
  Next: 'Next',
  No: 'No',
  NoBrowserSupportOfVideo: "Sorry, your browser doesn't support embedded videos.",
  NoDataYet: 'No data to display yet. Please come back later.',
  NotApplicable: 'N/A',
  November: 'November',
  October: 'October',
  Ok: 'OK',
  OpenInNewWindow: 'Open in a new window',
  Or: 'Or',
  Period: 'Period',
  PinnedMessage: 'Pinned message',
  Play: 'Play',
  Position: 'Position',
  Preview: 'Preview',
  PreviewAs: 'Preview as',
  PreviewOnMobile: 'Preview on your mobile',
  Price: 'Price',
  Private: 'Private',
  Public: 'Public',
  PublisherModal: {
    Description: 'Our solution requires that you use the Live Me Up publisher app as a camera and streaming source.',
    TutorialTitle: 'One last thing',
  },
  Remove: 'Remove',
  Required: 'This field is required',
  Retry: 'Retry',
  SaveAndClose: 'Save & close',
  Search: 'Search',
  SeeMore: 'See more',
  September: 'September',
  Settings: 'Settings',
  ShareFeedback: 'Share feedback',
  Since: 'Since {{date}}',
  Socials: {
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    OtherRTMPSource: 'Other RTMP destination',
    Youtube: 'Youtube',
  },
  Sort: {
    Label: '{{field}} : {{order}}',
    Order: {
      asc: 'ascending',
      asc_alpha: 'A-Z',
      desc: 'descending',
      desc_alpha: 'Z-A',
    },
    SortBy: 'Sort by',
  },
  Start: 'Start',
  Stats: 'Stats',
  Tenant: 'Store',
  Theme: 'Theme',
  Time: {
    d: 'd',
    h: 'h',
    m: 'm',
    s: 's',
  },
  Today: 'Today',
  TrafficReports: 'Monthly data',
  Undo: 'Undo',
  Update: 'Update',
  UpgradeForMore: 'Upgrade for more',
  UpgradeToActivate: 'Upgrade to activate',
  Usage: 'Usage',
  Users: 'Users',
  ViewInNewTab: 'View in a new tab',
  Yes: 'Yes',
  aNew_f: 'a new',
  aNew_m: 'a new',
  from: 'from',
};
